import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import ArtistsList from '../components/ArtistsList/ArtistsList'
import PageHeader from '../components/PageHeader/PageHeader'

const artistTemplate = ( { data: { strapi: { artists } }, location, pageContext } ) => {

	const trailYear = artists.length ? artists[ 0 ].artTrail.trailYear : ''

	return (
		<>
			<SEO title={ `Artists ${ trailYear }` } pathname={ location.pathname } />

			<PageHeader heading="Artists" />

			<ArtistsList artists={ artists } trailYear={ pageContext.index ? pageContext.trailYear : ''} />
		</>
	)
}

export default artistTemplate

export const query = graphql`
	query artistsQuery($id: ID!) {
		strapi {
			artists( where: { artTrail: $id }, sort: "artistName:ASC" ) {
				id
				artistName
				artistDescription
				artistWebsite
				artistSocial {
					id
					social
					socialLink
				}
				artistImage {
					url
					id
					imageFile {
						childImageSharp {
							fluid(maxWidth: 1000, maxHeight: 1000) {
								...GatsbyImageSharpFluid_withWebp_noBase64
							}
						}
					}
				}
				artTrail {
					trailYear
				}
			}
		}
	}
`