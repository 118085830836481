import React from 'react';

import ArtistCard from './ArtistCard'

export default function ArtistsList( props ) {

	const { artists, trailYear } = props

	return (
		<div className="info-list wrapper" >
			{ artists.map( ( artist, index ) => (
				<div className="info-list__container" key={ artist.id }>
					<ArtistCard
						artist={ artist }
						index={ index }
						trailYear={ trailYear } />
				</div>
			) ) }
		</div>
	);
}
